import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ConferenciasTemplate = ({
  content,
  contentComponent,
  fotoflyer,
  date,
  title,
  theme,
  organizadores,
  helmet,
  urlconferencia,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <img src={!!fotoflyer.childImageSharp ? fotoflyer.childImageSharp.fluid.src : fotoflyer} alt="foto flyer"/>
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>            
            <p><strong>Tema:</strong> {theme}</p>
            <p><strong>Fecha:</strong> {date}</p>
            <p><strong>Organizadores:</strong> {organizadores}</p>
            <p><a href={urlconferencia} rel="noopener noreferrer" target="_blank">Más información</a></p>

            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

ConferenciasTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  fotoflyer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  date: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  organizadores: PropTypes.string,
  helmet: PropTypes.object,
  urlconferencia: PropTypes.string
}

const Conferencias = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ConferenciasTemplate
        content={post.html}
        contentComponent={HTMLContent}
        fotoflyer={post.frontmatter.fotoflyer}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.theme}`}
            />
          </Helmet>
        }
        theme={post.frontmatter.theme}
        title={post.frontmatter.title}
        organizadores={post.frontmatter.organizadores}
        urlconferencia={post.frontmatter.urlconferencia}
      />
    </Layout>
  )
}

Conferencias.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Conferencias

export const pageQuery = graphql`
  query ConferenciasByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(
          formatString: "MMMM DD, YYYY",
          locale: "es-ES"
          )
        title
        theme
        organizadores
        urlconferencia
        fotoflyer {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
